.App {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  flex-direction: column;
}

#inputDiv {
  display: flex;
}

#imageDiv {
  display: flex;
  height: 256px;
  width: 256px;
  margin-top: 50px;
  background-color: white;
  padding: 20px;
}

#buttonDiv {
  margin-top: 20px;
  height: 50px;
}